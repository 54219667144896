export const Login = "/user/signin";
export const Save_location = "/save-location";
export const Pan_Verify = "/user/verify-pan-no";
export const Pan_VerifybyApp = "/empoloyee/verify-pan-no";
// export const Createcollection = "/attendence/create-collection";
export const Createcollection = "/api/createcollection";
export const Register = "/api/register";
export const UpdateCustomerAppEmployee = "/empoloyee/update-employee";
export const Registervianode = "/attendence/register";
export const Recognizevianode = "/attendence/recongnition";
export const SaveData = "/api/recognize";
export const AttendanceList = "/api/attendanceAws/";
export const Recognize = "/api/recognize";
export const ViewOneById = "/api/attendanceAwsById";
export const SavedData = "/api/localDataAttendance";
export const Attendance = "/attendence/save-attendence";
export const HRM_ATTENDANCE_LIST =
  "https://node-second.rupioo.com/attendance-calculate/"; ///DATABASE

// hrm userlist
export const All_Users_HRM = "/user/user-list-hrm/";
export const RegisteredAttendanceUser = "/attendence/attendance-list/";
export const logsData = "/api/localDataAttendance/";

export const RegisteredUser = "/user/view-register-user";
